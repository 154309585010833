import React, { useEffect, useState } from 'react';
import './styles/App.css';
import Services from './components/Services';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Certifications from './components/Certifications';
import Customers from './components/Customers';
import MobileWarningPopup from './components/MobileWarningPopup';
import TypingHeading from './components/TypingHeading';
import Logo from './components/Logo';

const App: React.FC = () => {
  const [showMobileWarning, setShowMobileWarning] = useState<boolean>(false);

  // useEffect(() => {
  //   // Check if the user has already acknowledged the popup
  //   const hasAcknowledged = localStorage.getItem('mobileWarningAcknowledged');

  //   if (!hasAcknowledged && isMobileDevice()) {
  //     setShowMobileWarning(true);
  //   }
  // }, []);

  // function isMobileDevice(): boolean {
  //   return window.innerWidth <= 768;
  // }

  // const handleAcknowledge = () => {
  //   // Mark that the user has acknowledged the warning
  //   localStorage.setItem('mobileWarningAcknowledged', 'true');
  //   setShowMobileWarning(false);
  // };

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
      </header>
      <div className='content'>
        {/* {showMobileWarning && (
          <MobileWarningPopup onAcknowledge={handleAcknowledge} />
        )} */}

        <div className="welcome-screen">
          <TypingHeading text='Creating a safer future' delay={150} />
          <img className='img-logo' src='/QuriusLogo.svg'></img>
        </div>

        <Services />
        <Certifications />
        <Customers />

      </div>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

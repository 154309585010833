import React, { useState, useEffect } from 'react';
import '../styles/TypingHeading.css'

interface TypeWriterProps {
    text: string;
    delay: number;
}

const TypingHeading: React.FC<TypeWriterProps> = ({ text, delay }) => {
    const [displayedText, setDisplayedText] = useState<string>('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setDisplayedText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, delay, text]);

    return <h1 className='TypingTextHeading'>{displayedText}</h1>;
};

export default TypingHeading;

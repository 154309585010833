import React from 'react';
import '../styles/Certifications.css';

interface Certification {
    title: string;
    imgPath: string;
}

const certs: Certification[] = [
    {
        title: "Offensive Security Certified Professional (OSCP)",
        imgPath: "/oscp.png"
    },
    {
        title: "Certified Red Team Professional (CRTP)",
        imgPath: "/crtp.png"
    },
    {
        title: "Certified Red Team Expert (CRTE)",
        imgPath: "/crte.png"
    },
    {
        title: "CREST Registered Penetration Tester (CRT)",
        imgPath: "/CREST.png"
    }
];

const Certifications: React.FC = () => {
    return (
        <section id="certs" className="certs">
            <div className="certifications-container">
                <div className='main-heading'>
                    <h1>Our Team</h1>
                </div>

                <p className='team-description'>We are a small team of certified security professionals from Slovakia and the Czech Republic, specializing in penetration testing, offensive software development, and security consulting. Our clients are small and medium-sized companies, as well as other security firms that engage us to strengthen and support their teams with our expertise and industry knowledge.</p>

                <div className="certs-list">
                    {certs.map((cert, index) => (
                        <div key={index} className="certs-card">
                            <img src={cert.imgPath}></img>
                            <h3>{cert.title}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default Certifications;

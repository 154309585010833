import React from 'react';
import '../styles/Footer.css'

const Footer: React.FC = () => {
    return (
        <div className="App-footer">
            <p>© 2024 QURIUSFOX Security Consulting.<br></br>All rights reserved.</p>
        </div>
    );
};

export default Footer;
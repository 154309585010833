import React from 'react';
import '../styles/Customers.css';

interface Customer {
    imgPath: string;
}

const customers: Customer[] = [
    {
        imgPath: "/eos.png"
    },
    {
        imgPath: "/bdo.png"
    },
    {
        imgPath: "/vut.png"
    },
    // {
    //     imgPath: "/bjsw.png"
    // }
];

const Customers: React.FC = () => {
    return (
        <section id="customers" className="customers">
            <div className="customers-container">
                <div className='main-heading'>
                    <h1>Our Satisfied Customers</h1>
                </div>
                <div className="customers-list">
                    {customers.map((cert, index) => (
                        <div key={index} className="customers-card">
                            <img src={cert.imgPath}></img>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default Customers;

import React from 'react';
import '../styles/Services.css';

interface Service {
    title: string;
    description: string;
}

const services: Service[] = [
    {
        title: "Web Application Penetration Testing",
        description: "Comprehensive analysis of your web application's or API's security posture with complience with OWASP methodology. The testing includes both automated and manual approach to discover vulnerabilities and mitigation recommendations."
    },
    {
        title: "Mobile Application Penetration Testing",
        description: "Thorough testing of your mobile applications to discover vulnerabilities that could pose potential threats impacting both it's users and your own infrastructure."
    },
    {
        title: "Infrastructure Penetration Testing",
        description: "Infrastructure penetration testing, also known as network or internal penetration testing, is a security assessment process that evaluates the security of your organization's infrastructure including discovery of vulnerabilities and misconfigurations along with testing of your company's threat detection and resillience capabilities."
    },
    {
        title: "Open-Source Intelligence",
        description: "Open-Source Intelligence, shortly OSINT, is a collection of techniques suited for gathering and analyzing publicly available information regarding a particular entity of interest. OSINT can be used as a means of determining the entity's internet exposure, searching for sensitive information and risk analysis regarding the discovered information."
    },
    {
        title: "Vulnerability Assessments",
        description: "Vulnerability assessments involve systematically identifying and evaluating weaknesses in an organization's systems, networks, and applications. The process includes scanning for known vulnerabilities using automated tools and manually verifying findings to ensure accuracy. "
    },
    {
        title: "General Security Consulting",
        description: "Are you having a specific inquiry that needs to be tailored specifically for you? Do not hestiate to contact us!"
    }
];

const Services: React.FC = () => {
    return (
        <section id="services" className="services">
            <div className="services-container">
                <div className='main-heading'>
                    <h1>Offensive Security Services</h1>
                </div>
                <div className="services-list">
                    {services.map((service, index) => (
                        <div key={index} className="service-card">
                            <h2>{service.title}</h2>
                            <p>{service.description}</p>
                        </div>
                    ))}
                </div>
                <p className='quotation-note'>*Contact us for a personalized quote and price estimate.</p>
            </div>
        </section>
    );
};

export default Services;
import { useState } from "react";
import "../styles/Navbar.css"

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <h1><a href="/">QURIUSFOX</a></h1>
      <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle navigation">
        ☰
      </button>
      <div className={`links ${isMenuOpen ? 'show' : ''}`}>
        <a href="#" onClick={toggleMenu}>Home</a>
        <a href="#services" onClick={toggleMenu}>Services</a>
        <a href="#certs" onClick={toggleMenu}>About Us</a>
        <a href="#contact" onClick={toggleMenu}>Contact</a>
      </div>
    </nav >
  );
}

export default Navbar;
import React from 'react';
import '../styles/Contact.css';

const Contact: React.FC = () => {
    return (
        <section id="contact" className="contact">
            <div className="contact-container">
                <div className="contact-info">
                    <h1 className="section-title">Contact Us</h1>
                    <p className="contact-description">Get in touch for more information or to schedule a consultation. Our team is here to help you with any questions you might have.</p>
                    <p className="contact-description"></p>
                    <br></br>
                    <strong><p>Ing. Patrik Píš</p></strong>
                    <p>Nové sady 988/2, Brno Staré Brno, 602 00</p>
                    <br></br>
                    <p><strong>Email:</strong> patrik.pis@protonmail.com</p>
                    <p><strong>ID:</strong> 18033709</p>
                </div>
            </div>
        </section>
    );
};

export default Contact;